<div class="modal-container">
	<div class="modal-close" (click)="close()">
		<button class="close">
			<img src="./assets/images/Cross.svg" alt="" />
		</button>
	</div>
	<div class="modal-header"></div>
	<div class="modal-body">
		<div class="row">
			<div class="col-lg-7">
				<app-gallery
					[data]="data"
					[dots]="true"
					[preview]="false"
					[showTitle]="false"
				></app-gallery>
				<div class="wrapper-dimensions">
					<div class="d-flex" style="gap: 5px">
						<p>
							<strong
								>{{
									lang === 'pt' ? 'Altura' : 'Altura'
								}}:</strong
							>
							{{ data.height }}
						</p>
						<p>
							<strong
								>{{
									lang === 'pt' ? 'Largura' : 'Ancho'
								}}:</strong
							>
							{{ data.width }}
						</p>
					</div>
					<p>
						<strong
							>{{
								lang === 'pt' ? 'Profundidade' : 'Profundidad'
							}}:</strong
						>
						{{ data.depth }}
					</p>
					<p><strong>Peso:</strong> {{ data.weight }}</p>
				</div>
			</div>
			<div class="col">
				<div class="wrapper-header">
					<div class="title w-100">
						<h4>{{ data.name }}</h4>
						<p *ngIf="data.price && lang === 'pt'">
							A partir de R$ {{ getPrice(data.price) }}
						</p>
					</div>
					<div class="w-50" *ngIf="data.line !== 'PLUS'">
						<app-mybutton
							*ngIf="data.buy_now"
							typeBtn="purchase"
							text="compre agora"
							[href]="data.buy_now"
						>
						</app-mybutton>
					</div>
				</div>
				<div class="wrapper">
					<div class="wrapper-items">
						<img
							[src]="data.temperature_icon.url"
							[alt]="data.temperature"
						/>
						<span>{{ data.temperature }}</span>
					</div>
					<div class="wrapper-items">
						<img
							[src]="data.server_until_icon.url"
							alt="Depósito"
						/>
						<span>{{ data.serves_until }}</span>
					</div>
					<div class="wrapper-items">
						<img
							[src]="data.capacity_icon.url"
							[alt]="data.capacity"
						/>
						<span>{{ data.capacity }}</span>
					</div>
					<div class="wrapper-items">
						<img
							[src]="data.compressor_system_icon.url"
							[alt]="data.compressor_system"
						/>
						<span>{{ data.compressor_system }}</span>
					</div>
					<div class="wrapper-items">
						<img
							[src]="data.voltage_icon.url"
							[alt]="dictionary.voltage"
						/>
						<span>{{ data.voltage }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="modal-footer">
		<div class="wrapper-items" *ngIf="data.line !== 'PLUS'">
			<div class="item">
				<p class="blue">
					{{
						lang === 'pt'
							? 'Condição de Pagamento'
							: 'Condición de pago'
					}}
				</p>
				<p>
					{{
						lang === 'pt'
							? 'Em até 12x sem juros no cartão'
							: 'Hasta 12 cuotas sin intereses en tu tarjeta'
					}}
				</p>
			</div>
			<div class="item">
				<p class="blue">
					{{ lang === 'pt' ? 'Distribuição' : 'Distribución' }}
				</p>
				<p>
					{{
						lang === 'pt'
							? 'Entrega em todo Brasil'
							: 'Entrega en todo Brasil'
					}}
				</p>
			</div>
			<div class="item">
				<p class="blue">
					{{ lang === 'pt' ? 'Instalação' : 'Instalación' }}
				</p>
				<p>{{ lang === 'pt' ? 'Grátis' : 'Gratis' }}</p>
			</div>
		</div>
		<div
			class="wrapper-items wrapper-items--plus"
			*ngIf="data.line === 'PLUS'"
		>
			<div class="item">
				<p class="blue">
					{{
						lang === 'pt'
							? 'Modelo destinado a estabelecimentos comerciais'
							: 'Modelo destinado a establecimientos comerciales'
					}}
				</p>
				<p>
					{{
						lang === 'pt'
							? 'Encontre a revenda mais próxima'
							: 'Encuentra el revendedor más cercano'
					}}
				</p>
			</div>
			<div class="item">
				<app-mybutton
					typeBtn="purchase"
					[text]="
						lang == 'pt'
							? 'Pesquisar revendas'
							: 'Buscar revendedores'
					"
					href="/pt/onde-encontrar-revenda-everest"
				>
				</app-mybutton>
			</div>
		</div>
	</div>
</div>
