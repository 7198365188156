import {Component, ElementRef, OnInit, AfterViewInit  } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Meta, Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalDefaultComponent } from 'src/app/components/modals/modal-default/modal-default.component'
import { ContentService } from 'src/app/services/content/content.service'
import { GenericValidatorService } from 'src/app/services/validators/generic-validator.service'
import { HttpErrorResponse } from '@angular/common/http';
import {AddressService} from '../../services/address/address.service'
import { ControlSelectOptions } from 'src/app/models/control-select-options'

@Component({
	selector: 'app-where-to-find',
	templateUrl: './where-to-find.component.html',
	styleUrls: ['./where-to-find.component.scss'],
})
export class WhereToFindComponent implements OnInit {

  autocomplete: google.maps.places.Autocomplete | undefined;
	constructor(
		private formBuilder: FormBuilder,
		private addressService: AddressService,
		private content: ContentService,
		private modal: NgbModal,
		private title: Title,
		private meta: Meta,
		private route: ActivatedRoute
	) {
		this.form = this.formBuilder.group({
			name: [
				null,
				[Validators.required, GenericValidatorService.completeName],
			],
			document_number: [
				null,
				[Validators.required, GenericValidatorService.isValidCpfCnpj],
			],
			phone: [
				null,
				[Validators.required, GenericValidatorService.validatePhone],
			],
			cell_phone: [
				null,
				[Validators.required, GenericValidatorService.validatePhone],
			],
			email: [null, [Validators.required, Validators.email]],
			model: [null, [Validators.required]],
		})
	}
	ufOptions: any[] = []
	cityOptions: any = []
	hasCity: any = ''
	city: string = ''
	addressResult: string = ''
    result: any = []
    currentIndex = 0;
    itemsPerLoad = 6;
    itemsToShow: any[] = [];
    noResults: boolean = false;
    renderForm: boolean = true;
    placeHolderAddress: string = ''
	modalSuccessTitle: string = ''
	modalSuccessText: string = ''
	modalErrorTitle: string = ''
	modalErrorText: string = ''
	type: any = 'water'
	mapsTextButton: string = ''
	whereToFindPageTitle: string = ''
	whereToFindPageSubtitle: any = ''
	whereToFindText: string = ''
	whereToFindPagePostal: string = ''
	whereToFindCategory: string = ''
	lang = 'pt'
	findAddress: string = ''
	mapsAddress: any = ''
	mapsNeighborhood: string = ''
	mapsCity: string = ''
	mapsState: string = ''
	notKnowCep = ''
	dataTabs: any = {}
    hasAddress: string = ''
    loading: boolean = false
	citiesNear: [] = []
	hasCitiesNear: ''
	ufSelected = '';
	optionService = 'assistance'
	page: any = 1
    pageSize: any = 6
    pageTotal: any
    data: any

	ngOnInit(): void {
		this.title.setTitle('Onde Encontrar')
		this.getStates()
		this.lang = this.route.snapshot.params.lang
		window.localStorage.setItem('lang', this.lang)
		const self = this
		this.content.getPage('dictionary/?limit=9999&', this.lang, {
			fnSuccess: (data: any) => {
				data.items.map((item: any) => {
					if (item.key === 'whereToFindPageTitle') {
						this.whereToFindPageTitle = item.value
					} else if (item.key === 'whereToFindPageSubtitle') {
						this.whereToFindPageSubtitle = item.value
					} else if (item.key === 'whereToFindCategory') {
						this.whereToFindCategory = item.value
					} else if (item.key === 'whereToFindText') {
						this.whereToFindText = item.value
					} else if (item.key === 'whereToFindPagePostal') {
						this.whereToFindPagePostal = item.value
					} else if (item.key === 'mapsTextButton') {
						this.mapsTextButton = item.value
					} else if (item.key === 'modalSuccessTitle') {
						this.modalSuccessTitle = item.value
					} else if (item.key === 'findAddress') {
						this.findAddress = item.value
					} else if (item.key === 'mapsAddress') {
						this.mapsAddress = item.value
					} else if (item.key === 'mapsNeighborhood') {
						this.mapsNeighborhood = item.value
					} else if (item.key === 'mapsCity') {
						this.mapsCity = item.value
					} else if (item.key === 'mapsState') {
						this.mapsState = item.value
					} else if (item.key === 'notKnowCep') {
						this.notKnowCep = item.value
					} else if (item.key === 'modalSuccessText') {
						this.modalSuccessText = item.value
					} else if (item.key === 'modalErrorTitle') {
						this.modalErrorTitle = item.value
					} else if (item.key === 'placeHolderAddress') {
                        this.placeHolderAddress = item.value
                    }
				})
			},
			fnError: (error: any) => console.warn(error),
		})
		this.content.getCategories('categories/', {
			fnSuccess(data) {
				self.dataTabs = data.items.filter((item: any) => item.slug !== 'elemento-filtrante' )
			},
			fnError(err) {
				console.warn(err)
			},
		})
	}
	getStates(): void {
        const self = this

        self.addressService.getStates({
            fnSuccess(response?: any) {
                self.ufOptions = response.map((state: any) => ({
                    label: state.name,
                    value: state.uf,
                }))

            },
            fnError(responseError) {
                console.log('err')
            }
        })
    }
    getCities(cityCep: string = ''): void {
        const self = this
        if (self.ufSelected) {
            self.addressService.getCities(cityCep, {
                fnSuccess(response?: any) {
                    self.cityOptions = response
                },
                fnError(responseError) {
                    console.log('err')
                }
            })
        }
    }

    onInputChange() {
        	this.result = []
            this.itemsToShow = []
            this.currentIndex = 0;
            this.city = ''
            this.hasCity = ''
			this.ufSelected = ''
			this.citiesNear = []
			this.noResults = false
			this.optionService = 'assistance'
    }
	onChange(hasCity: any) {
		this.hasCity = hasCity
	}
	onChangeCitiesNear(city: string) {
		this.hasCity = this.transform(city)
		this.searchByCep(1)
	}
    searchByCep(page:any) {
		const element = document.querySelector('.wrapper-search');
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
		this.citiesNear = []
        this.loading = true
        setTimeout(() => {
            const self = this
			self.page = page
            this.content.mapsForm('partners/regions/?machine=' + self.type + '&partners=' + self.optionService + '&page=' + page, {
                payload: { 
					city: self.hasCity,
					uf: this.ufSelected
					},
                fnSuccess(data: any) {
                    if (self.itemsToShow.length > 0) {
                        self.result = []
                        self.itemsToShow = []
                        self.currentIndex = 0;
						self.citiesNear= []
                    }
					self.data = data
                    self.result = data.results

					if(data.cities_near) {		
						self.result = []				
						self.citiesNear = data.cities_near
					}
                    self.noResults = false
                    if(self.result.length === 0 && self.citiesNear.length === 0){
                        self.noResults = true
                    }
                    self.loading = false
                },
                fnError(err: Error) {
                    console.warn(err)
                    self.result = []
                    self.noResults = true
                    self.addressResult = self.city
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 400 || err.status === 500) {
                            self.itemsToShow = []
                            self.currentIndex = 0;
                        }
                    }
                    self.loading = false
                },
            })
        }, 600);

    }
	changePagination(e:any) {
		this.searchByCep(e)
	}
    loadMoreItems() {
        for (let i = 0; i < this.itemsPerLoad; i++) {
            if (this.currentIndex < this.result.length) {
                this.itemsToShow.push(this.result[this.currentIndex]);
                this.currentIndex++;
            } else {
                break;
            }
        }
    }
	onSubmit() {
		const self = this
		if (this.form.valid) {
			const { name, document_number, phone, cell_phone, email, model } =
				this.form.value
			this.content.sendForm('budget_form', {
				payload: {
					name,
					document_number,
					phone,
					cell_phone,
					email,
					model,
				},
				fnSuccess(data: any) {
					self.form.reset()
					self.openModal(
						self.modalSuccessTitle,
						self.modalSuccessText
					)
				},
				fnError(err: Error) {
					self.openModal(self.modalErrorTitle, self.modalErrorText)
					console.warn(err)
				},
			})
		}
	}

    forceRerenderForm(): void {
        this.renderForm = false;
        setTimeout(() => {
            this.renderForm = true;
        });
    }
	transform(value: string): string {
		if (!value) return value;
	
		const prepositionsAndArticles = ["de", "da", "do", "dos", "das", "e", "em", "para", "com", "por", "no", "na", "nos", "nas"];
	
		const cityNameExceptions: Record<string, string[]> = {
		};
	
		return value
			.toLowerCase()
			.split(' ')
			.map((word, index, words) => {
				if (
					index === 0 || 
					!prepositionsAndArticles.includes(word) || 
					(index > 0 && words[index - 1] === 'de' && cityNameExceptions[value.split(' ')[index - 2]])
				) {
					return word.charAt(0).toUpperCase() + word.slice(1);
				} else {
					return word;
				}
			})
			.join(' ');
	}

	openModal(
		title: string = '',
		description: string = '',
		icon: string = 'alert.svg'
	): void {
		const modalRef = this.modal.open(ModalDefaultComponent)
		modalRef.componentInstance.title = title || 'Opa!'
		modalRef.componentInstance.desc =
			description ||
			'Algo não aconteceu como deveria, por favor tente novamente.'
		modalRef.componentInstance.figure = '/assets/images/' + icon
		modalRef.componentInstance.showFooter = false
	}

	modalActive: boolean = false
	routeAddress: string = ''
	routeLocalAddress: string = ''


	getRoute() {
		const from: string = this.routeLocalAddress.split(' ').join('+')
		const to: string = this.routeAddress.split(' ').join('+')
		const url: string = `https://www.google.com.br/maps/dir/${from}/${to}`
		window.open(url, '_blank')!.focus()
	}

	form: FormGroup
}
