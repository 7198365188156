<footer>
	<img
		src="assets/images/logo-slogan.svg"
		alt=""
		class="logo"
		style="margin-bottom: 16px; display: inline-block"
	/>
	<div class="row">
		<div class="col-lg-4">
			<div class="contact">
				<div
					*ngFor="let contact of dataContact"
					class="wrapper-contact"
				>
					<a
						*ngIf="contact.icon.alt.toLowerCase() === 'mail'"
						[href]="'mailto:' + contact.label"
						[attr.data-menuFooter]="contact.label"
						class="contact-info ga-menu-footer"
					>
						<img
							[src]="contact.icon.url"
							[alt]="contact.icon.alt"
							class="ga-menu-footer"
							[attr.data-menuFooter]="contact.label"
						/>
						<p
							class="ga-menu-footer"
							[attr.data-menuFooter]="contact.label"
						>
							{{ contact.label }}
						</p>
					</a>
					<a
						*ngIf="contact.icon.alt.toLowerCase() === 'sac'"
						[attr.data-menuFooter]="contact.label"
						class="contact-info ga-menu-footer"
					>
						<p class="sac">
							<span class="icon">SAC</span> - {{ sacDays }}<br />
							das 08:30h às 17:30h
							<span class="except">{{ sacAlert }}</span>
						</p>
					</a>
					<a
						*ngIf="contact.icon.alt.toLowerCase() === 'phone'"
						[href]="'tel:+55' + contact.label"
						[attr.data-menuFooter]="contact.label"
						class="contact-info ga-menu-footer"
					>
						<img
							[src]="contact.icon.url"
							[alt]="contact.icon.alt"
							class="ga-menu-footer"
							[attr.data-menuFooter]="contact.label"
						/>
						<p
							class="ga-menu-footer"
							[attr.data-menuFooter]="contact.label"
						>
							{{ contact.label }}
						</p>
					</a>
				</div>
				<div class="social">
					<a
						*ngFor="let social of dataSocials"
						[href]="social.url"
						class="ga-menu-footer"
						[attr.data-menuFooter]="social.icon.alt"
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							[src]="social.icon.url"
							[alt]="social.icon.alt"
							class="ga-menu-footer"
							[attr.data-menuFooter]="social.icon.alt"
						/>
					</a>
				</div>
			</div>
		</div>

		<div class="col-lg-4">
			<div class="newsletter">
				<h6>Newsletter</h6>
				<p [innerText]="newsletterSubText"></p>
				<form
					[formGroup]="form"
					[autocomplete]="'off'"
					(ngSubmit)="onSubmit()"
				>
					<div class="input">
						<app-input
							[placeholder]="''"
							[type]="'footer'"
							id="footer-email"
							name="email"
							typeInput="email"
							[labelName]="'E-mail'"
							[form]="form"
						>
						</app-input>
						<button
							type="submit"
							[disabled]="form.invalid"
							class="button-blue"
							[innerText]="newsletterButton"
						></button>
					</div>
				</form>
				<ul class="links">
					<li *ngFor="let submenu of dataMenuFooter">
						<a
							*ngIf="submenu.open_in_new_tab"
							[href]="submenu.url"
							class="ga-menu-footer"
							[attr.data-menuFooter]="submenu.label"
							target="_blank"
						>
							{{ submenu.label }}
						</a>
						<a
							*ngIf="
								!submenu.open_in_new_tab &&
								submenu.url.includes('http')
							"
							[href]="submenu.url"
							class="ga-menu-footer"
							[attr.data-menuFooter]="submenu.label"
						>
							{{ submenu.label }}
						</a>
						<a
							*ngIf="
								!submenu.open_in_new_tab &&
								!submenu.url.includes('http')
							"
							[routerLink]="
								'/' + langSelected + '/' + submenu.url
							"
							class="ga-menu-footer"
							[attr.data-menuFooter]="submenu.label"
						>
							{{ submenu.label }}
						</a>
					</li>

					<!-- <li><a href="#">Selo ABRAFIPA</a></li>
            <li><a [routerLink]="'/pt/downloads'">Download</a></li>
            <li><a [routerLink]="'/pt/faleConosco'">Fale conosco</a></li>
            <li><a href="#">Exportação</a></li> -->
				</ul>
			</div>
		</div>
		<div class="col-lg-4 text-center">
			<a
				style="margin-bottom: 24px; display: inline-block"
				href="https://www.reclameaqui.com.br/empresa/everest-purificadores-de-agua"
				target="_blank"
			>
				<figure>
					<img
						src="assets/images/selo-reclame-aqui.png"
						alt="Selo Reclame Aqui"
					/>
				</figure>
			</a>
		</div>
	</div>

	<div class="footer-bottom">
		<p [innerText]="footerBottom"></p>
	</div>
</footer>
