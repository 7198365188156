import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { FaqComponent } from './pages/faq/faq.component'
import { HomeComponent } from './pages/home/home.component'
import { MissionComponent } from './pages/mission/mission.component'
import { DifferentialComponent } from './pages/differential/differential.component'
import { ProductsComponent } from './pages/products/products.component'
import { PressRoomComponent } from './pages/press-room/press-room.component'
import { CertificationsComponent } from './pages/certifications/certifications.component'
import { EconomyTableComponent } from './pages/economy-table/economy-table.component'
import { DownloadsComponent } from './pages/downloads/downloads.component'
import { WhereToFindComponent } from './pages/where-to-find/where-to-find.component'
import { WhereToFindComponentOld } from './pages/where-to-find-old/where-to-find-old.component'
import { HistoryComponent } from './pages/history/history.component'
import { ContactComponent } from './pages/contact/contact.component'
import { RevendedorComponent } from './pages/revendedor/revendedor.component'
import { ElementoFiltranteComponent } from './pages/elemento-filtrante/elemento-filtrante.component'
import { InternaProdutoComponent } from './pages/interna-produto/interna-produto.component'
import { SustentabilidadeComponent } from './pages/sustentabilidade/sustentabilidade.component'
import { SeloABRAFIPAComponent } from './pages/selo-abrafipa/selo-abrafipa.component'
import { ExportationComponent } from './pages/exportation/exportation.component'
import { RevendaComponent } from './pages/revenda/revenda.component'
import { IceMachineComponent } from './pages/ice-machine/ice-machine.component'
import { CertificationsIceMachineComponent } from './pages/certifications-ice-machine/certifications-ice-machine.component'
import { DownloadsIceMachineComponent } from './pages/downloads-ice-machine/downloads-ice-machine.component'
import { PantanalComponent } from './pages/pantanal/pantanal.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/pt/home',
        pathMatch: 'full',
    },
    {
        path: '404',
        component: NotFoundComponent,
        data: { title: 'Página não encontrada' },
    },
    {
        path: ':lang',
        children: [
            {
                path: 'home',
                component: HomeComponent,
                data: {
                    title: 'Home',
                },
            },
            {
                path: 'perguntas-frequentes',
                component: FaqComponent,
                data: {
                    title: 'Perguntas frequentes',
                },
            },
            {
                path: 'missao',
                component: MissionComponent,
                data: {
                    title: 'Missão, Visão e Valores',
                },
            },
            {
                path: 'diferencial',
                component: DifferentialComponent,
                data: {
                    title: 'Diferencial',
                },
            },
            {
                path: 'diferencial/:differential',
                component: DifferentialComponent,
                data: {
                    title: 'Diferencial',
                },
            },
            {
                path: 'purificadores',
                component: ProductsComponent,
                data: {
                    title: 'Purificadores',
                },
            },
            {
                path: 'certificacoes',
                component: CertificationsComponent,
                data: {
                    title: 'Certificações',
                },
            },
            {
                path: 'certificacoes-maquinas-de-gelo',
                component: CertificationsIceMachineComponent,
                data: {
                    title: 'Certificações',
                },
            },
            {
                path: 'economia',
                component: EconomyTableComponent,
                data: {
                    title: 'Tabela de Economia',
                },
            },
            {
                path: 'manuais',
                component: DownloadsComponent,
                data: {
                    title: 'Manuais',
                },
            },
            {
                path: 'manual-maquinas-de-gelo',
                component: DownloadsIceMachineComponent,
                data: {
                    title: 'Manual máquinas de gelo',
                },
            },
            {
                path: 'onde-encontrar-assistencia-everest',
                component: WhereToFindComponent,
                data: {
                    title: 'Onde encontrar assistência',
                },
            },
            {
                path: 'onde-encontrar',
                component: WhereToFindComponent,
                data: {
                    title: 'Onde encontrar',
                },
            },
            {
                path: 'onde-encontrar-revenda-everest',
                component: WhereToFindComponent,
                data: {
                    title: 'Onde encontrar',
                },
            },
            {
                path: 'historia',
                component: HistoryComponent,
                data: {
                    title: 'Historia',
                },
            },
            {
                path: 'atendimento',
                component: ContactComponent,
                data: {
                    title: 'Atendimento',
                },
            },
            {
                path: 'seja-revendedor',
                component: RevendedorComponent,
                data: {
                    title: 'Seja um revendedor',
                },
            },
            {
                path: 'refil-original-everest',
                component: ElementoFiltranteComponent,
                data: {
                    title: 'Refil Original',
                },
            },
            {
                path: 'produto/maquinas-de-gelo',
                component: IceMachineComponent,
                data: {
                    title: 'Máquinas de Gelo',
                },
            },
            {
                path: 'produto/:slug',
                component: InternaProdutoComponent,
                data: {
                    title: 'Produto',
                },
            },
            {
                path: 'exportacao',
                component: ExportationComponent,
                data: {
                    title: 'Produto',
                },
            },
            {
                path: 'sustentabilidade',
                component: SustentabilidadeComponent,
                data: {
                    title: 'Sustentabilidade',
                },
            },
            {
                path: 'sos-pantanal',
                component: PantanalComponent,
                data: {
                    title: 'SOS Pantanal',
                },
            },
            {
                path: 'sala-de-imprensa',
                component: PressRoomComponent,
                data: {
                    title: 'Sala de Imprensa',
                },
            },
            {
                path: 'selo-ABRAFIPA',
                component: SeloABRAFIPAComponent,
                data: {
                    title: 'Selo ABRAFIPA',
                },
            },
            {
                path: 'maquinas-de-gelo',
                component: ProductsComponent,
                data: {
                    title: 'Maquinas de Gelo',
                },
            },
            {
                path: '404',
                component: NotFoundComponent,
                data: { title: 'Página não encontrada' },
            },
            {
                path: '**',
                redirectTo: '404',
                pathMatch: 'full',
                data: { title: 'Página não encontrada' },
            },
        ],
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }

