<div>
	<div class="form container">
		<div class="form-content">
			<form
				action="#"
				autocomplete="off"
				(ngSubmit)="onSubmit()"
				[formGroup]="form"
			>
				<div
					class="form-group"
					[ngClass]="{ active: step == 1, closed: step != 1 }"
				>
					<div class="row">
						<div class="col-md-6">
							<app-input
								[placeholder]="''"
								lettersOnly
								[type]="'revenda'"
								id="revenda-full_name"
								name="full_name"
								[labelName]="resellerInputFullName"
								[placeholder]="''"
								[form]="form"
							>
							</app-input>
						</div>
						<div class="col-md-6" *ngIf="lang == 'pt'">
							<app-input
								[placeholder]="onlyNumbersPlaceHolder"
								[type]="'revenda'"
								id="revenda-cpf"
								name="cpf"
								[labelName]="resellerInputFullCPF"
								[form]="form"
								[inputMask]="'000.000.000-00'"
							>
							</app-input>
						</div>
						<div class="col-md-6" *ngIf="lang !== 'pt'">
							<app-input
								[placeholder]="''"
								[type]="'revenda'"
								id="revenda-_id"
								name="_id"
								[labelName]="resellerInputId"
								[form]="form"
							>
							</app-input>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6" *ngIf="lang === 'pt'">
							<app-input
								[placeholder]="onlyNumbersPlaceHolder"
								[type]="'revenda'"
								id="revenda-phone"
								name="phone"
								[labelName]="resellerInputPhone"
								[form]="form"
								[inputMask]="'(00) 00000-0000'"
							>
							</app-input>
						</div>
						<div class="col-md-6" *ngIf="lang !== 'pt'">
							<app-input
								[placeholder]="onlyNumbersPlaceHolder"
								[type]="'revenda'"
								id="revenda-phoneEs"
								name="phone"
								[labelName]="resellerInputPhone"
								[form]="form"
							>
							</app-input>
						</div>
						<div class="col-md-6">
							<app-input
								[placeholder]="''"
								[type]="'revenda'"
								id="revenda-email"
								name="email"
								typeInput="email"
								[labelName]="resellerInputEmail"
								[form]="form"
							>
							</app-input>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<div class="input-select">
								<label for="">ESTADO</label>
								<select
									name="state"
									id=""
									formControlName="state"
									(change)="getCities(ufSelected)"
								>
									<option value="" selected disabled>
										Estado
									</option>
									<option
										*ngFor="let item of ufOptions"
										[ngValue]="item.value"
									>
										{{ item.label }}
									</option>
								</select>
							</div>
						</div>
						<div class="col-md-6">
							<div class="input-select">
								<label for="">CIDADE</label>
								<select
									name="city"
									id=""
									formControlName="city"
									(change)="onChange(hasCity)"
								>
									<option value="" selected disabled>
										Cidade
									</option>
									<option
										*ngFor="let item of cityOptions"
										[ngValue]="item"
									>
										{{ item }}
									</option>
								</select>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-6">
							<app-input
								class="w-100"
								[placeholder]="onlyNumbersPlaceHolder"
								[type]="'revenda'"
								id="revenda-cnpj_state_registration"
								name="cnpj_state_registration"
								[labelName]="resellerInputCNPJ"
								[form]="form"
								[inputMask]="'00.000.000/0000-00'"
								[inputNotRequired]="inputNotRequired"
							>
							</app-input>
						</div>
						<div class="col-md-6">
							<label for="" class="label-input">
								{{
									lang === 'pt'
										? 'Modelo de Negócio'
										: 'Modelo de Negocio'
								}}
							</label>
							<div class="wrapper-input-business-model">
								<div class="input-checkbox">
									<span class="checkmark"></span>
									<input
										type="checkbox"
										formControlName="business_model"
										name="business_model"
										id="revenda-purifier-business-model"
										form="form"
										[value]="
											'Revenda de Purificador de Água'
										"
										(change)="onCheckboxChange($event)"
									/>
									<label
										for="revenda-purifier-business-model"
									>
										{{
											lang === 'pt'
												? 'Revenda de Purificador de Água'
												: 'Reventa de Purificadores de Agua'
										}}
									</label>
								</div>
								<div class="input-checkbox">
									<span class="checkmark"></span>
									<input
										type="checkbox"
										formControlName="business_model"
										name="business_model"
										id="revenda-machine-business-model"
										form="form"
										[value]="'Revenda de Máquina de Gelo'"
										(change)="onCheckboxChange($event)"
									/>
									<label for="revenda-machine-business-model">
										{{
											lang === 'pt'
												? 'Revenda de Máquina de Gelo'
												: 'Reventa de Máquinas de Hielo'
										}}
									</label>
								</div>
								<div class="input-checkbox">
									<span class="checkmark"></span>
									<input
										type="checkbox"
										formControlName="business_model"
										name="business_model"
										id="assistance-business-model"
										form="form"
										[value]="'Assistência Técnica'"
										(change)="onCheckboxChange($event)"
									/>
									<label for="assistance-business-model">
										{{
											lang === 'pt'
												? 'Assistência Técnica'
												: 'Asistencia Técnica'
										}}
									</label>
									<p
										class="text-error"
										*ngIf="validationCheckboxModelBusiness"
									>
										{{
											lang === 'pt'
												? 'Por favor, selecione um modelo de negócio para continuar.'
												: 'Seleccione un modelo de negocio para continuar.'
										}}
									</p>
								</div>
							</div>
						</div>
					</div>
					<div
						style="
							width: 100%;
							height: 2px;
							background-color: #fff;
							margin: 15px 0;
							display: inline-block;
						"
					></div>
					<div class="row">
						<div class="col-12">
							<div class="input-checkbox">
								<span class="checkmark"></span>
								<input
									type="checkbox"
									formControlName="privacy_policy"
									name="privacy_policy"
									id="revenda-privacy_policy"
									form="form"
									(change)="validateChecked()"
								/>
								<label for="revenda-privacy_policy">{{
									lang === 'pt'
										? 'Estou de acordo com os critérios de investimento, tamanho de loja e equipe'
										: 'Acepte los criterios de inversión, el tamaño de la tienda y el personal para continuar.'
								}}</label>
								<p
									class="text-error"
									*ngIf="validationCheckbox"
								>
									{{
										lang === 'pt'
											? 'Por favor, concorde com os critérios de investimento, tamanho de loja e equipe para continuar.'
											: 'Acepte los criterios de inversión, el tamaño de la tienda y el personal para continuar.'
									}}
								</p>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
	<div class="">
		<img
			class="d-none"
			src="https://immakers4ds10371783.o18.link/p?o=20825827&m=19954&t=i&adv_sub1=form_revenda"
			width="1"
			height="1"
		/>
		<div class="wrapper-btn mt-4" (mouseenter)="validateCheckbox()">
			<app-mybutton
				(click)="onSubmit()"
				[disable]="form.invalid"
				[text]="resellerSendButton"
				center="center"
			></app-mybutton>
		</div>
	</div>
</div>
